var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8cbe1fd7957a48514bccce54f083dfbf55b71e7b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { AppEnv } from '@/store/store';
import * as Sentry from '@sentry/nextjs';

const enabledEnvs: AppEnv[] = ['staging', 'uat', 'production'];

let isInitialized = false;
export function initSentry(appEnv?: AppEnv, appRevision?: string) {
  // only enable Sentry for staging/uat/production
  if (
    !appRevision ||
    !appEnv ||
    !enabledEnvs.includes(appEnv) ||
    isInitialized
  ) {
    return;
  }

  isInitialized = true;

  Sentry.init({
    environment: appEnv,
    dsn: 'https://baa1cf7a63ec966601f24eb15b435f34@o4507248894541824.ingest.us.sentry.io/4507248896573440',
    release: appRevision,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.05,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate:
      appEnv === 'production' ? 0.1 : appEnv === 'uat' ? 0.05 : 0.05,

    // record all sessions in development
    // but only 10% in uat/production
    replaysSessionSampleRate:
      appEnv === 'production' ? 0.02 : appEnv === 'uat' ? 0.01 : 0,
    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // results in 25% of transactions being profiled (0.5*0.5=0.25)
    profilesSampleRate: 0.01,

    // You can remove this option if you're not planning to use the Sentry Session Replay feature:
    integrations: [
      Sentry.replayIntegration({
        // Additional Replay configuration goes in here, for example:
        // do not mask text and media on staging/uat to make debugging easier
        maskAllText: appEnv === 'production' ? true : false,
        blockAllMedia: appEnv === 'production' ? true : false,
        // only log network requests on staging/uat
        networkDetailAllowUrls:
          appEnv === 'production' ? undefined : [window.location.host],
        networkDetailDenyUrls: [],
      }),
      Sentry.browserTracingIntegration({
        enableInp: true,
      }),
      Sentry.browserProfilingIntegration(),
    ],
    // send PII in staging and UAT for debugging
    // DO NOT ENABLE THIS IN PRODUCTION
    // https://docs.sentry.io/platforms/javascript/configuration/integrations/httpclient/#senddefaultpii
    sendDefaultPii: appEnv !== 'production',
    attachStacktrace: true,
    ignoreErrors: [
      // https://kaligo.atlassian.net/browse/RCF-1253
      'Missing refresh token from localStorage',
      'Invalid token expiry',
      'User has been logged out',
      'Timeout',
      'Exchange token unsuccessful',
      'ResizeObserver loop completed with undelivered notifications',
      'Could not download source map specified in the minified js file',
      // For UUID errors similar to
      // https://app.rollbar.com/a/Ascenda/fix/item/rc-fe/1495?utm_campaign=exp_repeat_item_message&utm_medium=slack&utm_source=rollbar-notification
      /[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}$/gm,
      'Minified React error',
      `undefined is not an object (evaluating 'window.webkit.messageHandlers')`,
    ],
  });

  if (appEnv === 'staging') {
    Sentry.addIntegration(
      Sentry.feedbackIntegration({
        colorScheme: 'system',
        showEmail: false,
      }),
    );
  }
}
